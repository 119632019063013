import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/mzyorder_details/load',
    method: 'get',
    params
  })
}

export function add(data) {
  return request({
    url: '/mzyorder_details/add',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: '/mzyorder_details/update',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: '/mzyorder_details/delete',
    method: 'post',
    data
  })
}

export function getFile(data) {
  return request({
    url: '/mzyorder_details/GettemplatefieldsByfile',
    method: 'post',
    data
  })
}

